<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="isShow"
      width="30%"
      :before-close="handleClose"
      center
      @open="openFn"
    >
      <div class="content">
        <PassWordInput
          ref="pwd"
          @inputFinish="inputFinish"
          class="password-box"
        ></PassWordInput>
        <el-button @click="submit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import md5 from "js-md5";
export default {
  data() {
    return {
      password: "",
    };
  },
  props: {
    isShow: {
      type: Boolean,
    },
    title: {
      type: String,
      default: "请输入密码",
    },
    cardId: {
      type: String,
      default: "请输入密码",
    },
  },
  components: {
    PassWordInput: () => import("./PassWordInput.vue"),
  },
  methods: {
     clearPassword() {
      console.log(111);
      this.$refs.pwd.msg = "";
    },
    openFn() {
      if (this.$refs.pwd) {
        this.$refs.pwd.msg = "";
      }
    },
    handleClose() {
      this.$emit("close");
    },
    inputFinish(e) {
      this.password = e;
    },
    submit() {
      this.$emit('close')
      this.$emit("funt",this.password)
      this.$emit("binding",this.password)
      this.$emit("logoutHeader",this.password)
      // this.$router.go(-1)
      // console.log(this.password,this.cardId);

    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  text-align: center;
  .password-box {
    min-height: 200px;
  }
}
</style>